<template>
    <div class="app-body content a-flex-cfsfs">
        <div class="a-flex-cfsfs" style="flex:2;align-items: stretch;">
            <div class="content-layout a-flex-1" >
                <div class="a-flex-rsbc">
                    <span class="content-main-title">员工效率详情</span>
                </div>
                <div class="a-flex-rfsfs a-mt-20">
                    <div class="a-flex-cfsfs content-main-card a-flex-1">
                        <div class="a-flex-rfsbl">
                            <span>平均单日完成工单</span>
                            <span class="content-main-value">{{ workOrderRbacUserEfficiency.avgDayDoneWorkOrder || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsbl a-mt-10">
                            <span>平均单日完成巡检</span>
                            <span class="content-main-value">{{ workOrderRbacUserEfficiency.avgDayDonePatrolOrder || 0 }}</span>
                        </div>
                    </div>
                    <div class="a-flex-cfsfs content-main-card a-ml-20" style="flex: 0.8">
                        <div class="a-flex-rfsbl">
                            <span>完成工单平均耗时</span>
                            <span class="content-main-value">{{ workOrderRbacUserEfficiency.avgWorkOrderHour | initTime }}</span>
                        </div>
                        <div class="a-flex-rfsbl a-mt-10">
                            <span>当前未完成工单</span>
                            <span class="content-main-value">{{ workOrderRbacUserEfficiency.needDoneWorkOrder || 0 }}</span>
                        </div>
                    </div>
                </div>
                <staff-inspection-line :reqData='form'></staff-inspection-line>
            </div>
            <div class="content-layout a-mt-16 a-flex-1" >
                <div class="a-flex-rsbc">
                    <span class="content-main-title a-flex-1">{{ workOrderRbacUserInfoDetail.rbacUserName }}未完成的工单</span>
                    <span class="content-main-value">
                        {{ (workOrderRbacUserInfoDetail.processingNum || 0) 
                        + (workOrderRbacUserInfoDetail.processingOverdueNum || 0) 
                        + (workOrderRbacUserInfoDetail.pendingNum || 0) 
                        + (workOrderRbacUserInfoDetail.overdueNum || 0) }}
                    </span>
                    <span>个</span>
                </div>
                <div class="a-flex-rfsfs a-mt-20" style="align-items: stretch;">
                    <div class="a-flex-cfsfs content-main-card a-flex-1">
                        <div class="a-flex-rfsbl">
                            <span>待处理</span>
                            <span class="content-main-value">{{ workOrderRbacUserInfoDetail.pendingNum || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsbl a-mt-10">
                            <span>超时待处理</span>
                            <span class="content-main-value">{{ workOrderRbacUserInfoDetail.overdueNum || 0 }}</span>
                        </div>
                    </div>
                    <div class="a-flex-cfsfs content-main-card a-ml-20" style="flex: 0.8">
                        <div class="a-flex-rfsbl">
                            <span>处理中</span>
                            <span class="content-main-value">{{ workOrderRbacUserInfoDetail.processingNum || 0 }}</span>
                        </div>
                        <div class="a-flex-rfsbl">
                            <span>超时处理中</span>
                            <span class="content-main-value">{{ workOrderRbacUserInfoDetail.processingOverdueNum || 0 }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import staffInspectionLine from './inspection/staff-inspection-line.vue'
export default {
    components: {
        staffInspectionLine
    },
    data () {
        return {
            form: {
                companyId: '',
                provinceCode: '',
                cityCode: '',
                districtCode: '',
                rbacUserId: ''
            },
            workOrderRbacUserEfficiency: {},
            workOrderRbacUserInfoDetail: {}
        }
    },
    mounted () {
        this.form.provinceCode = this.$route.query.provinceCode
        this.form.cityCode = this.$route.query.cityCode
        this.form.districtCode = this.$route.query.districtCode
        this.form.companyId = this.$route.query.companyId
        this.form.rbacUserId = this.$route.query.rbacUserId
        this.getWorkOrderRbacUserEfficiency()
        this.getWorkOrderRbacUserInfoDetail()
    },
    filters: {
        initTime (time) {
            if(!time) {
                return '-'
            }
            if(time > 24) {
                let day = parseInt(time / 24) 
                let hour = time % 24
                return day + '天' + hour + '小时'
            }else {
                return time + '小时'
            }
        }
    },
    methods: {
        getWorkOrderRbacUserEfficiency () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderRbacUserEfficiency,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form
                }
            }).then(({data}) => {
                this.workOrderRbacUserEfficiency = Object.assign({}, data)
            })
        },
        getWorkOrderRbacUserInfoDetail () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderRbacUserInfoDetail,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form,
                }
            }).then(({data}) => {
                this.workOrderRbacUserInfoDetail = Object.assign({}, data)
            })
        },
        
    }
}
</script>

<style lang="scss" scoped>
.content{
    margin-top: 16px;
    overflow-y: auto;
    padding-bottom: 32px;
    height: 100%;
    align-items: stretch;
    .content-layout{
        background: #ffffff;
        border-radius: 4px;
        padding: 20px;
        .content-main-title{
            font-size: 14px;
            color: #333333;
            font-weight: 700;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
        }
        .content-main-title::before{
            content: "";
            display: inline-block;
            width: 4px;
            height: 14px;
            border-radius: 4px;
            background: #007af0;
            margin-right: 8px;
        }
        .content-main-morebtn{
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .content-main-card {
            padding: 12px;
            border-radius: 8px;
            font-size: 12px;
            color: #333333;
            background: rgb(242, 246, 252);
            font-weight: 500;
        }
        .content-main-value{
            font-size: 18px;
            font-weight: 700;
            padding: 0 10px;
            color: #FE7B11;
        }
    }
}
    
</style>